@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth;
}
body {
  @apply bg-body;
  color: white;
  @apply font-opensans;

  background-image: url(../src/assets/homebg2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
}
.left-50 {
  left: 50%;
}
.right-50 {
  right: 50%;
}
.top-50 {
  top: 50%;
}

.-z-1 {
  z-index: -1;
}

.secondary-title {
  @apply text-3xl font-bold;
}

.secondary-title::before {
  content: "";
  @apply block w-12 h-2 bg-theme rounded-full mb-1;
}

.section-paragraph {
  @apply text-secondary my-6 w-full max-w-3xl;
}

.badge {
  @apply px-4 py-2 text-sm rounded bg-badge;
}

[id] {
  scroll-margin-top: 100px;
}

.left-percentage {
  left: 5%;
}

.h-screen-partial {
  height: 80vh;
}

.nav-dot {
  transition: background 200ms, border-color 200ms;
}

.selected-circle {
  @apply border-theme bg-theme;
}

.nav-dot:hover span {
  opacity: 1;
}

.cardbody {
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  );
}
.social:hover {
  transform: scale(1.05);
}
.social_1:hover {
  transform: scale(1.05);
}
.frageantworten {
  margin-left: 1.5rem;
}
